import React from 'react';
import {
    Project as ProjectData
} from 'types';

type ProjectProps = {
    project: ProjectData
    index: number
}

export default function Project({
    project,
    index,
}: ProjectProps) {

    function renderLink() {
        return (
            <p>
                <strong>WEBSITE:</strong> <a target="_blank" href={ project.link } rel="noreferrer">{ project.link }</a>
            </p>
        );
    }

    return (
        <div key={ project.name } className="project-detail">
            <h5>{ project.name }</h5>
            {
                project.role === undefined
                    ? <></>
                    :
                    <h6>{ project.role }</h6>
            }
            {
                project.link === undefined
                    ? <></>
                    : renderLink()
            }


            <p><strong>DESCRIPTION:</strong> { project.description }</p>
            <p><strong>TECHNOLOGIES:</strong> { project.technologies }</p>
            {
                project.features === '' ? <></> : <p><strong>FEATURES:</strong> { project.features }</p>
            }
            {
                project.responsibility.length > 0
                    ? (
                        <>
                            <p><strong>RESPONSIBILITIES:</strong></p>
                            <ul>
                                {
                                    project.responsibility.map((line: string) => (
                                        <li key={ line }>{ line }</li>
                                    ))
                                }
                            </ul>
                        </>
                    )
                    : <></>
            }

        </div>
    );
}
